:root {
  --primary-color: #1e88e5;
}

* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  font-family: 'Google Sans', sans-serif;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

header {
  display: grid;
  align-items: center;
  height: 64px;
  min-height: 64px;
  font-size: 1.25em;
  padding: 0 1.5em;
  font-weight: 600;
  background-color: #e3f2fd;
  grid-auto-flow: column;
  justify-content: flex-start;
  grid-gap: 0.5em;

  img {
    width: 42px;
    height: 20px;
  }
}

main {
  display: grid;
  grid-template-columns: 400px 1fr;
  flex: 1;
  overflow: hidden;
  position: relative;

  .drop {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    top: 0;
    bottom: 0;
    z-index: 1;
    padding: 1em;
    opacity: 0;
    pointer-events: none;

    &[active] {
      opacity: 1;
    }

    &-border {
      border: 0.2em dashed #fff;
      height: 100%;
      border-radius: 1em;
      display: grid;
      place-items: center;
      color: #fff;
      font-size: 1.5em;
    }
  }

  aside {
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 1.5em;
  }

  .form {
    display: grid;
    grid-gap: 1.5em;
    margin-bottom: auto;
    background-color: #fff;

    .input {
      display: grid;
      grid-gap: 0.4em;
      justify-items: flex-start;

      & > label {
        font-size: 0.9em;
      }

      button {
        height: 40px;
        padding: 0 1.5em;
        border-radius: 40px;
        border: none;
        outline: none;
        cursor: pointer;
        font-weight: 500;
        font-size: 0.9em;
        background-color: var(--primary-color);
        color: #fff;
        font-family: 'Google Sans', sans-serif;
      }

      input[type="text"] {
        height: 40px;
        border-radius: 1em;
        width: 100%;
        border: 1px solid #9e9e9e;
        outline: none;
        padding: 0 1em;
      }

      .file {
        input[type="file"] {
          display: none;
        }
      }

      .range {
        display: grid;
        grid-template-columns: 1fr 60px;
        grid-gap: 0.25em;
        width: 100%;

        input {
          width: 100%;
          accent-color: var(--primary-color);
        }

        div {
          text-align: right;
          font-size: 0.9em;
          letter-spacing: -0.025em;
        }
      }

      .radio {
        display: grid;
        grid-auto-flow: column;
        overflow: hidden;
        border: 1px solid #9e9e9e;
        height: 40px;
        border-radius: 40px;

        input {
          display: none;

          &:disabled,
          &:disabled + label  {
            display: none;
          }
        }

        input:checked + label {
          background-color: #bbdefb;
        }

        label {
          display: grid;
          align-items: center;
          padding: 0 1.25em;
          cursor: pointer;
          text-align: center;
          border-left: 1px solid #9e9e9e;
          font-size: 0.9em;

          &:first-of-type {
            border-left: none;
          }
        }
      }
    }
  }

  .preview {
    background-color: #eeeeee;
    border: 1px solid #9e9e9e;
    border-radius: 8px;
    place-items: center;
    margin: 1.5em 1.5em 1.5em 0;
    padding: 3em;
    overflow: hidden;

    .canvas {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
      display: flex;
      
      canvas {
        display: block;
        margin: auto;
        max-width: 100%;
        max-height: 100%;
        // background-image: linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%), linear-gradient(45deg, #eee 25%, #fff 25%, #fff 75%, #eee 75%);
        // background-size: 30px 30px;
        // background-position: 0 0, 15px 15px;
      }

      &[data-shape="circle"] canvas {
        border-radius: 50%;
      }
    }
  }

  .fab {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    grid-gap: 0.5em;
    position: fixed;
    right: 3em;
    bottom: 3em;
    height: 56px;
    border-radius: 56px;
    padding: 0 2em 0 1.7em;
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    outline: none;
    font-size: 1em;
    font-weight: 500;
    font-family: 'Google Sans', sans-serif;
    cursor: pointer;
  }

  footer {
    margin-top: 3em;
    font-size: 0.8em;
    text-align: center;
    opacity: 0.8;

    a {
      color: #0d47a1;
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 720px) {
  header {
    letter-spacing: -0.025em;
    padding: 0 1em;
    grid-gap: 0.35em;
  }

  main {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: 'preview' 'aside';

    aside {
      grid-area: aside;
      padding: 1em 1em 6em;
    }

    .preview {
      grid-area: preview;
      margin: 1em;
      padding: 1em;
    }

    .fab {
      right: 1rem;
      bottom: 1rem;
    }
  }
}