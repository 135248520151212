:root {
  --primary-color: #1e88e5;
}

* {
  box-sizing: border-box;
}

html, body {
  height: 100vh;
  flex-direction: column;
  margin: 0;
  font-family: Google Sans, sans-serif;
  display: flex;
}

header {
  height: 64px;
  min-height: 64px;
  grid-gap: .5em;
  background-color: #e3f2fd;
  grid-auto-flow: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 1.5em;
  font-size: 1.25em;
  font-weight: 600;
  display: grid;
}

header img {
  width: 42px;
  height: 20px;
}

main {
  flex: 1;
  grid-template-columns: 400px 1fr;
  display: grid;
  position: relative;
  overflow: hidden;
}

main .drop {
  width: 100%;
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  background-color: #00000080;
  padding: 1em;
  position: absolute;
  top: 0;
  bottom: 0;
}

main .drop[active] {
  opacity: 1;
}

main .drop-border {
  height: 100%;
  color: #fff;
  border: .2em dashed #fff;
  border-radius: 1em;
  place-items: center;
  font-size: 1.5em;
  display: grid;
}

main aside {
  flex-direction: column;
  padding: 1.5em;
  display: flex;
  overflow: auto;
}

main .form {
  grid-gap: 1.5em;
  background-color: #fff;
  margin-bottom: auto;
  display: grid;
}

main .form .input {
  grid-gap: .4em;
  justify-items: flex-start;
  display: grid;
}

main .form .input > label {
  font-size: .9em;
}

main .form .input button {
  height: 40px;
  cursor: pointer;
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  border-radius: 40px;
  outline: none;
  padding: 0 1.5em;
  font-family: Google Sans, sans-serif;
  font-size: .9em;
  font-weight: 500;
}

main .form .input input[type="text"] {
  height: 40px;
  width: 100%;
  border: 1px solid #9e9e9e;
  border-radius: 1em;
  outline: none;
  padding: 0 1em;
}

main .form .input .file input[type="file"] {
  display: none;
}

main .form .input .range {
  grid-gap: .25em;
  width: 100%;
  grid-template-columns: 1fr 60px;
  display: grid;
}

main .form .input .range input {
  width: 100%;
  accent-color: var(--primary-color);
}

main .form .input .range div {
  text-align: right;
  letter-spacing: -.025em;
  font-size: .9em;
}

main .form .input .radio {
  height: 40px;
  border: 1px solid #9e9e9e;
  border-radius: 40px;
  grid-auto-flow: column;
  display: grid;
  overflow: hidden;
}

main .form .input .radio input, main .form .input .radio input:disabled, main .form .input .radio input:disabled + label {
  display: none;
}

main .form .input .radio input:checked + label {
  background-color: #bbdefb;
}

main .form .input .radio label {
  cursor: pointer;
  text-align: center;
  border-left: 1px solid #9e9e9e;
  align-items: center;
  padding: 0 1.25em;
  font-size: .9em;
  display: grid;
}

main .form .input .radio label:first-of-type {
  border-left: none;
}

main .preview {
  background-color: #eee;
  border: 1px solid #9e9e9e;
  border-radius: 8px;
  place-items: center;
  margin: 1.5em 1.5em 1.5em 0;
  padding: 3em;
  overflow: hidden;
}

main .preview .canvas {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

main .preview .canvas canvas {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  display: block;
}

main .preview .canvas[data-shape="circle"] canvas {
  border-radius: 50%;
}

main .fab {
  grid-gap: .5em;
  height: 56px;
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 56px;
  outline: none;
  grid-auto-flow: column;
  align-items: center;
  padding: 0 2em 0 1.7em;
  font-family: Google Sans, sans-serif;
  font-size: 1em;
  font-weight: 500;
  display: grid;
  position: fixed;
  bottom: 3em;
  right: 3em;
}

main footer {
  text-align: center;
  opacity: .8;
  margin-top: 3em;
  font-size: .8em;
}

main footer a {
  color: #0d47a1;
  text-decoration: underline;
}

@media screen and (max-width: 720px) {
  header {
    letter-spacing: -.025em;
    grid-gap: .35em;
    padding: 0 1em;
  }

  main {
    grid-template: "preview" 1fr
                   "aside" 1fr
                   / 1fr;
  }

  main aside {
    grid-area: aside;
    padding: 1em 1em 6em;
  }

  main .preview {
    grid-area: preview;
    margin: 1em;
    padding: 1em;
  }

  main .fab {
    bottom: 1rem;
    right: 1rem;
  }
}

/*# sourceMappingURL=index.7a522e22.css.map */
